

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { unselectFirstElement,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  copyObject,
  recursiveFillData,
  onCurrencyFocus,
  downloadFile } from '@/helper/functions';

import { exportToPDF, saveZusammenfassungPDF } from '@/helper/handle-pdf';
import apiBackend from '@/service/api-insurance';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import priceStatusIcon from '@/components/form/price-status-icon.vue';

type biogasanlageItemAnzahlDerMotorenJeAnlageType = {
  isSelected: boolean;
  VersicherungsortMotor: string;
  VersicherungssummeMotor: null | number;
  ArtDesMotors: string;
  Bewertungsjahr: string;
  Selbstbeteiliung: null | number;
  SelbstbeteiliungPercentage: null | number;
  SelbstbeteiliungMin: null | number;
  SelbstbeteiliungMax: null | number;
  NachlassFurKaskodeckungBHKW: null | number;
  ZuschlagRegelenergieFlexbetriebMotor: null | number;
  VSU371: null | number;
}

const paramsVertragsdatenItem = {
  isSelected: false,
  sumInsured: '',
  sum371: '',
  paymentMethod: '',
  versicherungsteuer: '',
  netization: '',
  factorLevel: '2024',
};
const paramsVertragsdatenType = 1;

const biogasanlageItemAnzahlDerMotorenJeAnlage: biogasanlageItemAnzahlDerMotorenJeAnlageType = {
  isSelected: false,
  VersicherungsortMotor: '',
  VersicherungssummeMotor: null,
  ArtDesMotors: '',
  Bewertungsjahr: '',
  Selbstbeteiliung: null,
  SelbstbeteiliungPercentage: null,
  SelbstbeteiliungMin: null,
  SelbstbeteiliungMax: null,
  NachlassFurKaskodeckungBHKW: null,
  ZuschlagRegelenergieFlexbetriebMotor: null,
  VSU371: 0,
};

const maschineAbweichendVomBiogasanlagentarifItem = {
  isSelected: false,
  versicherungsort: '',
  MKZ: '',
  Beschreibung: '',
  Versicherungssumme: null,
  Bewertungsjahr: '',
  Selbstbeteiliung: '',
  NettobeitragVorRabattenUndZuschlagen: null,
};

const biogasanlageItem = {
  isSelected: false,
  VersicherungsortAnlage: '',
  ArtDerAnlage: '',
  ProduktvarianteDerAnlage: '',
  NachlassFurKaskodeckungAnlage: '',
  NachlassFurKaskodeckungBHKW: '',
  BU: 'Nein',
  biogasanlageItemAnzahlDerMotorenJeAnlageItems: [
    copyObject(biogasanlageItemAnzahlDerMotorenJeAnlage),
  ] as biogasanlageItemAnzahlDerMotorenJeAnlageType[],
  VersicherungssummeAnlageOhneMotor: null,
  Bewertungsjahr: '',
  SelbstbeteiliungAnlageOhneMotor: null,
  SelbstbeteiliungPercentageAnlageOhneMotor: null,
  SelbstbeteiliungMinAnlageOhneMotor: null,
  SelbstbeteiliungMaxAnlageOhneMotor: null,
  VersicherungssummeFermenterbiologie: null,
  SelbstbeteiliungFermenterbiologie: null,
  SelbstbeteiliungPercentageFermenterbiologie: null,
  SelbstbeteiliungMinFermenterbiologie: null,
  SelbstbeteiliungMaxFermenterbiologie: null,
  Deckungserweiterungen: {
    opened: false,
    BrandBlitzschlagExplosionen: 'Nein',
    EinbruchdiebstahlRaub: 'Nein',
    ErdbebenUberschwemmungHochwasser: 'Nein',
    ZuschlagRegelenergieFlexbetriebGesamteAnlage: '',
  },
  MaschineAbweichendVomBiogasanlagentarif: {
    opened: false,
    items: [
      copyObject(maschineAbweichendVomBiogasanlagentarifItem),
    ],
  },
  VSU371: 0,
};
const selbstbeteiligungsvariantenSubItem = {
  SB: null,
  SbPercent: null,
  SbMin: null,
  SbMax: null,
};

const selbstbeteiligungsvariantenItem = {
  SelbstbeteiligungMotor: {
    ...copyObject(selbstbeteiligungsvariantenSubItem),
  },
  SelbstbeteiligungAnlage: {
    ...copyObject(selbstbeteiligungsvariantenSubItem),
  },
  SelbstbeteiligungFermenterbiologie: {
    ...copyObject(selbstbeteiligungsvariantenSubItem),
  },
  name: '',
};

const selbstbeteiligungsvariantenItemSBTarif = {
  SelbstbeteiligungMotor: {
    ...copyObject(selbstbeteiligungsvariantenSubItem),
    SB: 1000,
  },
  SelbstbeteiligungAnlage: {
    ...copyObject(selbstbeteiligungsvariantenSubItem),
    SB: 1000,
  },
  SelbstbeteiligungFermenterbiologie: {
    ...copyObject(selbstbeteiligungsvariantenSubItem),
    SbPercent: 10,
    SbMin: 1000,
  },
  name: '',
};

const versicherungsortItem = {
  num: 0,
  isSelected: false,
  Strabe: '',
  PLZ: '',
  Ort: '',
  land: '',
  ZursZone: '',
  Naturgefahren1: '',
  Naturgefahren2: '',
  Naturgefahren3: '',
  Geltungsbereich: '',
  IndividuelleVereinbarungenZumGeltungsbereich: '',
};

const versicherungsortType = 2;

const deckungserweiterungenItem = {
  variante1: 'Ja',
  variante2: 'Ja',
  variante3: 'Ja',
};
const deckungserweiterungenType = 3;

const erstrisikosummenType = 4;

const erstrisikosummenElement = {
  isStaticValue: false,
  name: '',
  ERS: '',
  GeanderteERS: null,
  disabled: false,
};

const kompaktdeckung = 'Kompaktdeckung';
const optimaldeckung = 'Optimaldeckung';

const showFermenterbiologieCondition = [
  kompaktdeckung,
  optimaldeckung,
];

export default Vue.extend({
  name: 'biogas-insurance-tarif',
  components: {
    priceStatusIcon,
  },
  mixins: [dataChangeMixin],
  data() {
    return {
      name: 'biogas-insurance-tarif',
      key: 'BIOGAS_INSURANCE',
      backUrl: 'biogas-insurance',
      ByParamsVertragsdatenType: paramsVertragsdatenType,
      paramsVertragsdatenItems: [
        copyObject(paramsVertragsdatenItem),
      ],
      versicherungsort: {
        Strabe: '',
        PLZ: '',
        Ort: '',
        land: '',
        ZursZone: '',
        Naturgefahren1: '',
        Naturgefahren2: '',
        Naturgefahren3: '',
        Geltungsbereich: '',
        IndividuelleVereinbarungenZumGeltungsbereich: '',
      },
      byVersicherungsortType: versicherungsortType,
      versicherungsortItems: [
        {
          ...copyObject(versicherungsortItem),
        },
      ],
      biogasanlageItems: [
        copyObject(biogasanlageItem),
      ],
      selbstbeteiligungsvariantenItems: {
        SbTarif: {
          ...copyObject(selbstbeteiligungsvariantenItemSBTarif),
          name: 'SB- Tarif',
          disabled: true,
        },
        SbVariante1: {
          ...copyObject(selbstbeteiligungsvariantenItem),
          name: 'SB-Variante 1',
          disabled: false,
        },
        SbVariante2: {
          ...copyObject(selbstbeteiligungsvariantenItem),
          name: 'SB-Variante 2',
          disabled: false,
        },
        SbVariante3: {
          ...copyObject(selbstbeteiligungsvariantenItem),
          name: 'SB-Variante 3',
          disabled: false,
        },
      },
      Deckungserweiterungen: {
        items: [
          copyObject(deckungserweiterungenItem),
        ],
        JHE: null,
      },
      byDeckungserweiterungenType: deckungserweiterungenType,
      byErstrisikosummenType: erstrisikosummenType,
      ErstrisikosummenItems: [
        {
          isStaticValue: true,
          name: 'Aufräumungs-, Bergungs-, Dekontaminations- und Entsorgungskosten',
          ERS: 500000,
          GeanderteERS: null,
          disabled: false,
        },
        {
          isStaticValue: true,
          name: 'Feuerlöschkosten',
          ERS: 100000,
          GeanderteERS: null,
          disabled: false,
        },
        {
          isStaticValue: true,
          name: 'Mehrkosten infolge behördlicher Auflagen',
          ERS: 100000,
          GeanderteERS: null,
          disabled: false,
        },
        {
          isStaticValue: true,
          name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
          ERS: 100000,
          GeanderteERS: null,
          disabled: false,
        },
        {
          isStaticValue: true,
          name: 'Bewegungs- und Schutzkosten',
          ERS: 25000,
          GeanderteERS: null,
          disabled: false,
        },
        {
          isStaticValue: true,
          name: 'Mehrkosten für Luftfracht',
          ERS: 25000,
          GeanderteERS: null,
          disabled: false,
        },
        {
          isStaticValue: true,
          name: 'Kosten für Erd-, Maurer- und Stemmarbeiten, Gerüststellung und Bereitstellung eines Provisoriums',
          ERS: 25000,
          GeanderteERS: null,
          disabled: false,
        },
        {
          isStaticValue: true,
          name: 'Kosten für Wiederherstellung von Daten und Programmen',
          ERS: 10000,
          GeanderteERS: null,
          disabled: false,
        },
        {
          isStaticValue: true,
          name: 'Miet- und Leihkosten während der Reparatur oder Kosten für Finanzierungs- und Leasingraten',
          ERS: 10000,
          GeanderteERS: null,
          disabled: true,
        },
        {
          isStaticValue: true,
          name: 'Schäden an Gebäuden',
          ERS: 10000,
          GeanderteERS: null,
          disabled: true,
        },
        {
          isStaticValue: true,
          name: 'Zusatzgeräte, Reserveteile und Fundamente',
          ERS: 10000,
          GeanderteERS: null,
          disabled: true,
        },
      ],
      deductibleVariantsCssClasses: {
        SbTarif: 'bg-gray',
        SbVariante1: 'table-variant-1',
        SbVariante2: 'table-variant-2',
        SbVariante3: 'table-variant-3',
      },
      tariffPremiumCalculationCssClasses: {
        tarif: 'bg-gray',
        variant1: 'table-variant-1',
        variant2: 'table-variant-2',
        variant3: 'table-variant-3',
      },
      tariffPremiumCalculation: {
        SbTarif: {
          name: 'Tarif SB',
          priceKey: 'tarif',
        },
        SbVariante1: {
          name: 'SB Variante 1',
          priceKey: 'variant1',
        },
        SbVariante2: {
          name: 'SB Variante 2',
          priceKey: 'variant2',
        },
        SbVariante3: {
          name: 'SB Variante 3',
          priceKey: 'variant3',
        },
      },
      pdfStyles: false,
      refImportFileName: 'excelImport',
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'productId', 'id', 'processSavingData', 'insuranceFormsApiData', 'priceData'],
  watch: {
    processSavingData(value: boolean) {
      if (!value && this.insuranceData?.basicData !== null && this.insuranceData?.spreadsheetData == null) {
        this.fillVersicherungsort(this.insuranceData.basicData.versicherungsort);
        this.createBiogasanlageItems(this.insuranceData.basicData.anzahlDerAnlagen ?? 1, this.insuranceData.basicData.AnzahlDerMotorenJeAnlage ?? 1, this.insuranceData.basicData.ArtDerAnlage ?? '');
        this.importDataFromBasic(this.insuranceData.basicData);
      }
    },
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
    showBu(value: boolean) {
      this.$store.commit('tabsModule/setMenuParams', {
        selectedBu: value,
      });
      this.$store.commit('insuranceModule/biogasModule/setSelectedBu', value);
    },
    biogasanlageItems: {
      deep: true,
      handler(newVal, oldVal) {
        newVal.forEach((item, index) => {
          if (item.ProduktvarianteDerAnlage === 'Kompaktdeckung') {
            this.setSelbstbeteiligungsvariantenValues(1000, null, null);
          } else if (item.ProduktvarianteDerAnlage === 'Optimaldeckung') {
            this.setSelbstbeteiligungsvariantenValues(null, 10, 1000);
          }
        });
      },
    },
    'priceData.tarif.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant1.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant2.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant3.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
  },
  methods: {
    onCurrencyFocus,
    fillPriceResponse(newVal, oldVal) {
      let currentIndex = 0;
      this.biogasanlageItems.forEach((element: any, index) => {
        element.biogasanlageItemAnzahlDerMotorenJeAnlageItems.forEach((biogasanlageItemAnzahlDerMotorenJeAnlageItem: any, i) => {
          // eslint-disable-next-line no-param-reassign
          biogasanlageItemAnzahlDerMotorenJeAnlageItem.VSU371 = Number((Number(biogasanlageItemAnzahlDerMotorenJeAnlageItem.VersicherungssummeMotor) / Number(newVal.Result.Maschinen[currentIndex].Summenfaktor)).toFixed(0));
          currentIndex += 1;
        });
        if (element.VersicherungssummeAnlageOhneMotor > 0) {
          // eslint-disable-next-line no-param-reassign
          element.VSU371 = Number((Number(element.VersicherungssummeAnlageOhneMotor) / Number(newVal.Result.Maschinen[currentIndex].Summenfaktor)).toFixed(0));
          currentIndex += 1;
        }
        if (element.ProduktvarianteDerAnlage === 'Kompaktdeckung' || element.ProduktvarianteDerAnlage === 'Optimaldeckung') {
          currentIndex += 1;
        }
      });
    },
    uploadFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-places-excel`, 'import', file).then((response: any) => {
          this.importPositionsdatenFromExcel(response.data);
          this.excelImportRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    chooseImportFile() {
      this.excelImportRef.click();
    },
    importPositionsdatenFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.versicherungsortItems.length === 1 &&
        !this.versicherungsortItems[0].Strabe &&
        !this.versicherungsortItems[0].PLZ &&
        !this.versicherungsortItems[0].Ort &&
        !this.versicherungsortItems[0].land;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.versicherungsortItems[0] : copyObject(versicherungsortItem);

        newItem.Strabe = item.street;
        newItem.PLZ = item.postCode;
        newItem.Ort = item.place;
        newItem.land = item.land;

        if (!fillFirst) {
          this.versicherungsortItems.push(newItem);
        }
      });
    },
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 1);
    },
    checkZuschlagRegelenergieFlexbetriebMotorFilled(biogasesItem) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of biogasesItem.biogasanlageItemAnzahlDerMotorenJeAnlageItems) {
        if (item.ZuschlagRegelenergieFlexbetriebMotor !== null && item.ZuschlagRegelenergieFlexbetriebMotor !== '') {
          return true;
        }
      }
      return false;
    },
    checkZuschlagRegelenergieFlexbetriebGesamteAnlageFilled(biogasesItem) {
      return biogasesItem.Deckungserweiterungen.ZuschlagRegelenergieFlexbetriebGesamteAnlage !== null && biogasesItem.Deckungserweiterungen.ZuschlagRegelenergieFlexbetriebGesamteAnlage !== '';
    },
    setSelbstbeteiligungsvariantenValues(SB, SbPercent, SbMin) {
      this.selbstbeteiligungsvariantenItems.SbTarif.SelbstbeteiligungFermenterbiologie.SB = SB;
      this.selbstbeteiligungsvariantenItems.SbTarif.SelbstbeteiligungFermenterbiologie.SbPercent = SbPercent;
      this.selbstbeteiligungsvariantenItems.SbTarif.SelbstbeteiligungFermenterbiologie.SbMin = SbMin;
    },
    queueGetPriceRequest(variantPrice: string) {
      this.$emit('add-to-queue', 'getPriceRequestUseId', this.mapSbVariante(variantPrice));
      this.submitForm(false, false);
    },
    queueGetPrice(variantPrice: string) {
      this.$emit('add-to-queue', 'getPriceUseId', variantPrice);
      this.submitForm(false, false);
    },
    mapSbVariante(variant: string): string {
      const data = {
        SbTarif: 'tarif',
        SbVariante1: 'variant1',
        SbVariante2: 'variant2',
        SbVariante3: 'variant3',
      };

      return data[variant] || variant;
    },
    importDataFromBasic(data: any) {
      const { versicherungssummeJeMotor } = data;
      const { versicherungssummeJeAnlage } = data;
      const { versicherungssummeFermenterbiologieJeAnlage } = data;
      const { SBMaschine } = data;
      const { SBAnlage } = data;
      const { SBFermenterbiologie } = data;

      if (versicherungssummeJeMotor) {
        this.biogasanlageItems.forEach((itm, i) => {
          itm.biogasanlageItemAnzahlDerMotorenJeAnlageItems.forEach((subitem, j) => {
            if (!subitem.VersicherungssummeMotor) {
              this.biogasanlageItems[i].biogasanlageItemAnzahlDerMotorenJeAnlageItems[j].VersicherungssummeMotor = versicherungssummeJeMotor;
            }
          });
        });
      }

      if (versicherungssummeJeAnlage) {
        this.biogasanlageItems.forEach((itm, i) => {
          if (!itm.VersicherungssummeAnlageOhneMotor) {
            this.biogasanlageItems[i].VersicherungssummeAnlageOhneMotor = versicherungssummeJeAnlage;
          }
        });
      }

      if (versicherungssummeFermenterbiologieJeAnlage) {
        this.biogasanlageItems.forEach((itm, i) => {
          if (!itm.VersicherungssummeFermenterbiologie) {
            this.biogasanlageItems[i].VersicherungssummeFermenterbiologie = versicherungssummeFermenterbiologieJeAnlage;
          }
        });
      }

      if (SBAnlage && !this.selbstbeteiligungsvariantenItems.SbVariante1.SelbstbeteiligungMotor.SB) {
        this.selbstbeteiligungsvariantenItems.SbVariante1.SelbstbeteiligungMotor.SB = SBAnlage;
      }

      if (SBMaschine && !this.selbstbeteiligungsvariantenItems.SbVariante1.SelbstbeteiligungAnlage.SB) {
        this.selbstbeteiligungsvariantenItems.SbVariante1.SelbstbeteiligungAnlage.SB = SBMaschine;
      }

      if (SBFermenterbiologie && !this.selbstbeteiligungsvariantenItems.SbVariante1.SelbstbeteiligungFermenterbiologie.SB) {
        this.selbstbeteiligungsvariantenItems.SbVariante1.SelbstbeteiligungFermenterbiologie.SB = SBFermenterbiologie;
      }
    },
    exportToPDF() {
      exportToPDF.call(this, `Zusammenfassung-Biogas-Tarif-${this.id}.pdf`);
    },
    savePdf() {
      saveZusammenfassungPDF.call(this, this.id, 'saveTarifPDF', this.onSubmit);
    },
    deleteErstrisikosummenItem(key) {
      this.ErstrisikosummenItems.splice(key, 1);
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.ByParamsVertragsdatenType:
          duplicateDataItems(paramsVertragsdatenItem, this.paramsVertragsdatenItems, fillData);
          break;
        case this.byVersicherungsortType:
          duplicateDataItems(versicherungsortItem, this.versicherungsortItems, fillData);
          break;
        case this.byDeckungserweiterungenType:
          duplicateDataItemsWithoutSelect(deckungserweiterungenItem, this.Deckungserweiterungen.items);
          break;
        case this.byErstrisikosummenType:
          if (this.ErstrisikosummenItems.length < 16) {
            duplicateDataItemsWithoutSelect(erstrisikosummenElement, this.ErstrisikosummenItems);
          }
          break;
        default:
          console.log('Wrong type');
      }
    },
    deleteRows(type: number) {
      let items;
      switch (type) {
        case this.ByParamsVertragsdatenType:
          unselectFirstElement(this.paramsVertragsdatenItems);

          items = this.paramsVertragsdatenItems.filter((item) => !item.isSelected);
          this.paramsVertragsdatenItems = items;
          break;
        case this.byVersicherungsortType:
          unselectFirstElement(this.versicherungsortItems);

          items = this.versicherungsortItems.filter((item) => !item.isSelected);
          this.versicherungsortItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },

    deleteBiogasanlageItemAnzahlDerMotorenJeAnlageItemRows(index: number) {
      unselectFirstElement(this.biogasanlageItems[index].biogasanlageItemAnzahlDerMotorenJeAnlageItems);

      const items = this.biogasanlageItems[index].biogasanlageItemAnzahlDerMotorenJeAnlageItems.filter((item) => !item.isSelected);
      this.biogasanlageItems[index].biogasanlageItemAnzahlDerMotorenJeAnlageItems = items;
    },
    isShowFermenterbiologie(index: number): boolean {
      return showFermenterbiologieCondition.includes(
        this.biogasanlageItems[index].ProduktvarianteDerAnlage,
      );
    },
    deleteMaschineAbweichendVomBiogasanlagentarifItemRows(index: number) {
      unselectFirstElement(this.biogasanlageItems[index].MaschineAbweichendVomBiogasanlagentarif.items);

      const items = this.biogasanlageItems[index].MaschineAbweichendVomBiogasanlagentarif.items.filter((item) => !item.isSelected);
      this.biogasanlageItems[index].MaschineAbweichendVomBiogasanlagentarif.items = items;
    },
    addBiogasanlageItemAnzahlDerMotorenJeAnlageItem(index: number, fillData: boolean) {
      const addItem = copyObject(biogasanlageItemAnzahlDerMotorenJeAnlage);

      addItem.ArtDesMotors = this.fillArtDesMotors(this.biogasanlageItems[index].ArtDerAnlage);

      duplicateDataItems(addItem, this.biogasanlageItems[index].biogasanlageItemAnzahlDerMotorenJeAnlageItems, fillData);
    },
    firsAddBiogasanlageItemAnzahlDerMotorenJeAnlageItem(itemAnzahlDerMotorenJeAnlage: biogasanlageItemAnzahlDerMotorenJeAnlageType, index: number, fillData: boolean) {
      duplicateDataItems(itemAnzahlDerMotorenJeAnlage, this.biogasanlageItems[index].biogasanlageItemAnzahlDerMotorenJeAnlageItems, fillData);
    },
    addMaschineAbweichendVomBiogasanlagentarifItem(index: number, fillData: boolean) {
      duplicateDataItems(maschineAbweichendVomBiogasanlagentarifItem, this.biogasanlageItems[index].MaschineAbweichendVomBiogasanlagentarif.items, fillData);
    },
    duplicateBiogasanlageItem(fillData: boolean) {
      duplicateDataItems(biogasanlageItem, this.biogasanlageItems, fillData);
    },
    deleteRowsBiogasanlageItem() {
      unselectFirstElement(this.biogasanlageItems);

      this.biogasanlageItems = this.biogasanlageItems.filter((item) => !item.isSelected);
    },
    fillVersicherungsort(versicherungsort: any) {
      this.versicherungsortItems = [
        {
          num: 0,
          isSelected: false,
          Strabe: versicherungsort.Strabe,
          PLZ: versicherungsort.PLZ,
          Ort: versicherungsort.Ort,
          ZursZone: versicherungsort.ZursZone,
          IndividuelleVereinbarungenZumGeltungsbereich: versicherungsort.IndividuelleVereinbarungenZumGeltungsbereich,
          land: '',
          Naturgefahren1: '',
          Naturgefahren2: '',
          Naturgefahren3: '',
          Geltungsbereich: '',
        },
      ];
    },
    createBiogasanlageItems(anzahlDerAnlagen: number, AnzahlDerMotorenJeAnlage: number, ArtDerAnlage: string) {
      this.biogasanlageItems = [];

      for (let i = 0; i < anzahlDerAnlagen; i += 1) {
        const item = copyObject(biogasanlageItem);

        item.ArtDerAnlage = ArtDerAnlage;
        item.biogasanlageItemAnzahlDerMotorenJeAnlageItems[0].ArtDesMotors = this.fillArtDesMotors(ArtDerAnlage);

        this.biogasanlageItems.push({
          ...item,
        });
      }

      const biogasanlageItemAnzahlDerMotorenJeAnlageItem = copyObject(biogasanlageItemAnzahlDerMotorenJeAnlage);

      biogasanlageItemAnzahlDerMotorenJeAnlageItem.ArtDesMotors = this.fillArtDesMotors(ArtDerAnlage);

      this.biogasanlageItems.forEach((element: any, index) => {
        for (let j = 1; j < AnzahlDerMotorenJeAnlage; j += 1) {
          this.firsAddBiogasanlageItemAnzahlDerMotorenJeAnlageItem(biogasanlageItemAnzahlDerMotorenJeAnlageItem, index, false);
        }
      });
    },
    fillArtDesMotors(ArtDerAnlage: string) {
      if (ArtDerAnlage === 'Biogasanlage mit Zündstrahlverfahren') {
        return 'Zündstrahlverfahren';
      }
      if (ArtDerAnlage === 'Biogasanlage mit Gasottoverfahren') {
        return 'Gasottoverfahren';
      }
      return '';
    },
    onSubmit() {
      this.submitForm(!this.showBu);
    },
    submitForm(nextPrice = false, changeTabId = true) {
      const data = {
        paramsVertragsdaten: this.paramsVertragsdatenItems,
        versicherungsortItems: this.versicherungsortItems,
        biogasanlageItems: this.biogasanlageItems,
        selbstbeteiligungsvariantenItems: this.selbstbeteiligungsvariantenItems,
        Deckungserweiterungen: this.Deckungserweiterungen,
        ErstrisikosummenItems: this.ErstrisikosummenItems,
      };

      this.$emit('spreadsheet-data-change', data, this.key, nextPrice, changeTabId);
    },
    setValues(insuranceData: any) {
      this.paramsVertragsdatenItems = insuranceData.paramsVertragsdaten;
      this.versicherungsortItems = insuranceData.versicherungsortItems;
      this.biogasanlageItems = insuranceData.biogasanlageItems;
      this.selbstbeteiligungsvariantenItems = insuranceData.selbstbeteiligungsvariantenItems;
      this.Deckungserweiterungen = insuranceData.Deckungserweiterungen;
      this.ErstrisikosummenItems = insuranceData.ErstrisikosummenItems;
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.spreadsheetData) {
        for (let i = 1; i < this.insuranceFormsApiData.spreadsheetData.versicherungsortItems.length; i += 1) {
          duplicateDataItems(versicherungsortItem, this.versicherungsortItems, false);
        }
        recursiveFillData(this.versicherungsortItems, this.insuranceFormsApiData.spreadsheetData.versicherungsortItems);
        recursiveFillData(this.paramsVertragsdatenItems, this.insuranceFormsApiData.spreadsheetData.paramsVertragsdatenItems);
        recursiveFillData(this.biogasanlageItems, this.insuranceFormsApiData.spreadsheetData.biogasanlageItems);
        recursiveFillData(this.ErstrisikosummenItems, this.insuranceFormsApiData.spreadsheetData.ErstrisikosummenItems);
      }
    },
    downloadInsurancePlacesTemplate() {
      apiBackend.downloadInsurancePlaces().then((response) => {
        downloadFile(response.data, 'Einlesen von Versicherungsorten.xlsx');
      });
    },
    exportPlacesData() {
      const data = this.versicherungsortItems.map((itm) => ({
        street: itm.Strabe,
        code: `${itm.PLZ}`,
        city: itm.Ort,
        land: itm.land,
      }));

      apiBackend.exportPlacesData(data).then((response) => {
        downloadFile(response.data, 'Versicherungsort-Biogas.xlsx');
      });
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData && this.insuranceData.spreadsheetData) {
      this.setValues(this.insuranceData.spreadsheetData);
    }

    if (this.insuranceData?.basicData !== null && this.insuranceData?.spreadsheetData == null) {
      this.fillVersicherungsort(this.insuranceData.basicData.versicherungsort);
      this.createBiogasanlageItems(this.insuranceData.basicData.anzahlDerAnlagen ?? 1, this.insuranceData.basicData.AnzahlDerMotorenJeAnlage ?? 1, this.insuranceData.basicData.ArtDerAnlage ?? '');
      this.importDataFromBasic(this.insuranceData.basicData);

      if (this.insuranceFormsApiData) {
        this.fillApiValuesData();
      }
    }
    (this as any).startWatcherActivated();
  },
  computed: {
    countVersicherungsortItems(): number {
      return this.versicherungsortItems.length;
    },
    showPlusBtn(): boolean {
      return (this.ErstrisikosummenItems.length < 16);
    },
    showMinusBtn(): boolean {
      return (this.ErstrisikosummenItems.length > 12);
    },
    showFermenterbiologie(): boolean {
      const values = this.biogasanlageItems.map((item) => showFermenterbiologieCondition.includes(
        item.ProduktvarianteDerAnlage,
      ));

      return values.filter((itm) => itm).length > 0;
    },
    showBu(): boolean {
      const values = this.biogasanlageItems.map((item) => item.BU === 'Ja');

      return values.filter((itm) => itm).length === this.biogasanlageItems.length;
    },
    excelImportRef(): any {
      return this.$refs[this.refImportFileName];
    },
    factorLevelYears(): number[] {
      const years: number[] = [];
      const startYear = (new Date()).getFullYear();
      const toYear = 2021;
      let year = startYear;

      while (year >= toYear) {
        years.push(year);

        year -= 1;
      }

      return years;
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
  },
});
